@font-face {
  font-family: "GT America Medium";
  src: url("../assets/fonts/GT-America/GT-America-Standard-Medium.eot");
  src: url("../assets/fonts/GT-America/GT-America-Standard-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/GT-America/GT-America-Standard-Medium.woff2")
      format("woff2"),
    url("../assets/fonts/GT-America/GT-America-Standard-Medium.woff")
      format("woff"),
    url("../assets/fonts/GT-America/GT-America-Standard-Medium.ttf")
      format("truetype");
}

@font-face {
  font-family: "GT America";
  src: url("../assets/fonts/GT-America/GT-America-Standard-Regular.eot");
  src: url("../assets/fonts/GT-America/GT-America-Standard-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/GT-America/GT-America-Standard-Regular.woff2")
      format("woff2"),
    url("../assets/fonts/GT-America/GT-America-Standard-Regular.woff")
      format("woff"),
    url("../assets/fonts/GT-America/GT-America-Standard-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "Cambon";
  src: url("../assets/fonts/Cambon/Cambon-Book.woff2") format("woff2"),
    url("../assets/fonts/Cambon/Cambon-Book.woff") format("woff");
}
