.button-focusStyles {
  @apply focus:outline-none;
  @apply focus-visible:ring-2;
  @apply focus-visible:ring-moss--dark;
}

.button-focusStyles--dark {
  @apply focus:outline-none;
  @apply focus-visible:ring-2;
  @apply focus-visible:ring-forest;
}

.button-focusStyles--clean {
  @apply focus:outline-none;
  @apply focus-visible:ring-2;
  @apply focus-visible:ring-forest;
}

.button--fill {
  position: initial !important;

  &:before {
    content: "";
    @apply absolute;
    @apply inset-0;
  }
}
