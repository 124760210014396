#snackbars-portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1001;
  pointer-events: none;

  @screen md {
    top: initial;
    bottom: calc(var(--unit) * 10);
    gap: calc(var(--unit) * 4);
    flex-direction: column-reverse;
  }
}
