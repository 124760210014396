meter {
  --meterRadius: 999px;
  --meterValueColor: var(--c-blue-400);
  --meterBgColor: var(--c-blue-400);

  width: 100%;
  height: 32px;
  /* For Firefox */
  background: var(--meterBgColor);
  border-radius: var(--meterRadius);
}

/* WebKit */
meter::-webkit-meter-bar {
  background: var(--meterBgColor);
  border-radius: var(--meterRadius);
  border: none;
  box-shadow: none;
  height: 32px;
}

meter::-webkit-meter-optimum-value,
meter::-webkit-meter-suboptimum-value,
meter::-webkit-meter-even-less-good-value {
  border-radius: var(--meterRadius);
}

meter::-webkit-meter-optimum-value {
  background: var(--meterValueColor);
  border: none;
  outline: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Firefox */
meter::-moz-meter-bar {
  border-radius: var(--meterRadius);
  border: none;
}

meter:-moz-meter-optimum::-moz-meter-bar {
  background: var(--meterValueColor);
  outline: none;
  border: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
