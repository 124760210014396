select {
  padding: calc(var(--unit) * 3.5) calc(var(--unit) * 4) !important;
  background-position: right calc(var(--unit) * 4) bottom calc(var(--unit) * 4) !important;

  /* overwrite the default tailwind css select caret */
  background-image: url("data:image/svg+xml,%3Csvg width='5px' height='8px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='rotate(90 6 5)'%3E%3Cpolyline stroke='currentColor' stroke-width='1.5' points='1 1 5.5 5.5 1 10'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E%0A") !important;
  background-size: 0.8em 0.8em !important;

  @supports (-moz-appearance: meterbar) {
    text-indent: -2px;
  }

  &:focus {
    outline: none !important;
  }
}
