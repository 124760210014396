input[type="text"]:not(.codeField),
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea.input {
  @apply ring-0;
  @apply text-base;
  @apply text-black-70;
  @apply outline-none;
  @apply focus:outline-none;
  @apply border-initialFieldBorder;
  @apply focus:shadow-inputFocus;
  @apply focus:border-forest;
  @apply focus:ring-0;
  @apply w-full;
  @apply block;
  @apply bg-white;
  @apply rounded-md;
  font-weight: 400;
  padding: var(--textFieldPadding);

  &::placeholder {
    @apply text-black-50;
  }

  .field--large & {
    @apply text-3xl;
    @apply p-2;
  }

  &.input--dark {
    @apply border;
    @apply border-darkFieldBorder;
    @apply focus:!border-forest;
  }
}
