.ReactModal__Overlay {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply flex-wrap;
  @apply opacity-0;
  @apply cursor-pointer;

  background-color: var(--c-modalBg) !important;
  transition: opacity var(--modalTransitionMs) ease-in-out;
  z-index: 1000;
}

.ReactModal__Overlay--after-open {
  @apply opacity-100;
}

.ReactModal__Overlay--before-close {
  @apply opacity-0;
}

.ReactModal__Content {
  inset: initial !important;
  transition: opacity var(--modalTransitionMs) ease-out;
  @apply opacity-0;
  @apply outline-none;
  @apply cursor-auto;
  @apply md:pointer-events-none;

  &:focus-visible {
    outline: inherit;
  }
}

.ReactModal__Content--after-open {
  @apply opacity-100;
  .modal-content {
    transform: translateY(0) scale(1);
  }
}

.ReactModal__Content--before-close {
  @apply opacity-0;
  .modal-content {
    transform: translateY(-20px) scale(1);
  }
}

.modal-content {
  transform: translateY(var(--verticalTranslation)) scale(0.98);
  transition: transform var(--modalTransitionMs) ease-out;
  @apply md:pointer-events-auto;

  @screen md {
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.05);
  }
}

/* Class added to body element - turns off background scrolling */
.ReactModal__Body--open {
  @apply fixed;
  @apply w-full;
  @apply h-full;
  @apply overflow-hidden;
}
