.StripeElement {
  @apply p-2;
  @apply border;
  @apply border-black-20;
  @apply rounded-md;
  @apply transition;
  @apply transition-colors;
  @apply bg-white;
  padding: var(--textFieldPadding);
}

.StripeElement--focus {
  @apply border-forest;
  @apply shadow-inputFocus
}
