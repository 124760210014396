.PercentageField {
  label {
    padding-bottom: 0;
  }

  input[type="number"] {
    @apply text-5xl;
    @apply pl-0;
    @apply pr-10;
    border-color: transparent;
  }
}
