.border-ticket-clipping {
  --ticketBorderHeight: 10px;
  overflow: visible !important;
  &:before,
  &:after {
    content: "";
    height: var(--ticketBorderHeight);
    @apply absolute;
    @apply left-0;
    @apply right-0;
    background-image: url("../../assets/images/Ticket_Clipping.svg");
    @apply bg-repeat;
    @apply bg-top;
  }
  &:before {
    top: calc(var(--ticketBorderHeight) * -1);
  }
  &:after {
    bottom: calc(var(--ticketBorderHeight) * -1);
    @apply transform;
    @apply rotate-180;

    @screen sm-only {
      background: none;
    }
  }
}
