video::cue {
  @apply font-regular;
  @apply text-white;
  @apply bg-transparent;
}

.VideoControls {
  &:before,
  &:after {
    content: "";
    display: block;
    height: 100px;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
  }
  &:before {
    @apply top-0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  }

  &:after {
    @apply bottom-0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0));
  }
}

.VideoControls--active {
  &:before,
  &:after {
    opacity: 1;
  }
}

/* Playhead */

.VideoControls {
  --playhead-size: 20px;
}

.VideoControls input[type="range"] {
  @apply bg-transparent;
  @apply rounded-sm;
  @apply cursor-pointer;
  @apply outline-none;
  @apply appearance-none;
  @apply absolute;
  @apply h-full;
}

.VideoControls input[type="range"]:focus {
  @apply outline-none;
}

.VideoControls input[type="range"]::-webkit-slider-thumb {
  @apply appearance-none;
  @apply border-none;
  @apply bg-forest;
  height: var(--playhead-size);
  width: var(--playhead-size);
  border-radius: calc(var(--playhead-size) / 2);
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.2);
}

.VideoControls input[type="range"]::-moz-range-thumb {
  @apply appearance-none;
  @apply border-none;
  @apply bg-forest;

  height: var(--playhead-size);
  width: var(--playhead-size);
  border-radius: calc(var(--playhead-size) / 2);
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.2);
}

/* Progress */

.VideoControls-container {
  @apply transition-transform;
  @apply absolute;
  @apply left-0;
  @apply right-0;
  @apply bottom-0;
  @apply w-full;
  @apply flex;
  @apply flex-none;
  @apply items-center;
  @apply mx-auto;
  @apply p-4;
  @apply max-w-[700px];
  @apply md:bottom-4;
  @apply transform;
  @apply translate-y-full;

  .VideoControls--active & {
    @apply translate-y-0;
  }

  &:hover {
    & .VideoControls-loaded,
    & .VideoControls-played {
      transform: scaleY(200%);
    }
  }
}

.VideoControls-loaded,
.VideoControls-played {
  transition: transform 0.1s ease-in-out;
  @apply appearance-none;
  @apply border-none;
  @apply h-1;
  @apply w-full;
}

.VideoControls-loaded {
  @apply bg-grey;
}

.VideoControls-loaded::-webkit-progress-value,
.VideoControls-loaded::-moz-progress-bar {
  @apply bg-grey-400;
}

.VideoControls-played {
  @apply bg-none;
}

.VideoControls-played::-webkit-progress-value {
  @apply bg-forest;
}
.VideoControls-played::-moz-progress-bar {
  @apply bg-forest;
}
