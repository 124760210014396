/* hack to improve dev refresh times (file gets cached by webpack) */
@tailwind base;

@layer base {
  :root {
    --bp-sm: 568px;
    --bp-md: 768px;
    --bp-lg: 1200px;

    --unit: 4px;

    --gap: calc(var(--unit) * 8);

    /* padding */
    --textFieldPadding: calc(var(--unit) * 3.5) calc(var(--unit) * 4);

    /* colors */
    --c-defaultBg: #f6f6f6; /* Note: This hex code is also used in public/index.html. If changed here, please update <meta name="theme-color" content="..." /> with the new color */
    --c-initialFieldBorder: color-mod(#000 a(10%));
    --c-darkFieldBorder: color-mod(#000 a(20%));

    --c-blue: #026fb4;
    --c-blue-500: #026fb4;
    --c-blue-400: #52baed;
    --c-blue-400--hover: color-mod(#52baed shade(10%));
    --c-blue-300: color-mod(#52baed a(26%));
    --c-blue-200: #ebf7fb;

    --c-green: #60c444;
    --c-green-700: #1f7308;
    --c-green-600: color-mod(#60c444 shade(20%));
    --c-green-500: #60c444;
    --c-green-400: #e9ffe3;

    --c-red: #f53429;
    --c-red-300: #ffc8c5;
    --c-red-error: #cc463e;

    --c-yellow-200: #fdf9ea;
    --c-yellow-300: #fff3c6;
    --c-yellow-400: color-mod(#fff3c6 shade(20%));
    --c-yellow: #f3cb33;
    --c-yellow-600: color-mod(#f3cb33 blackness(10%));
    --c-yellow-900: #4e4208;

    /* Monochromatic */
    --c-white: #ffffff;
    --c-black: #000000;
    --c-grey: #d4d4d4;
    --c-grey-400: #f0f0f0;
    --c-grey-300: #fcfcfc;
    --c-black-5: color-mod(#000 a(5%));
    --c-black-10: color-mod(#000 a(10%));
    --c-black-20: color-mod(#000 a(20%));
    --c-black-50: color-mod(#000 a(50%));
    --c-black-70: color-mod(#000 a(70%));

    /* Text Sizing */
    --text-base-size: 15px;
    --text-base-lineHeight: 1.25;
    --text-md-size: 18px;
    --text-lg-size: 28px;
    --text-lg-lineHeight: 32px;

    /* Circle Icon */
    --circle-icon-sm: 48px;
    --circle-icon: 56px;
    --circle-icon-lg: 72px;

    /* Modal */
    --c-modalBg: rgba(24, 24, 30, 0.85);
    --modalCloseButtonOffset: calc(var(--unit) * 12);

    /* Ethos */
    --c-moss: #dbede5;
    --c-moss--dark: #c4dbd4;
    /* forest color is also used statically in icon.svg for the favicon  */
    --c-forest: #054742;
    --c-forest--hover: color-mod(#054742 lightness(19%));
    --c-salamander: #fa640a;
    --c-salamander--hover: color-mod(#fa640a lightness(19%));
    --c-salamander--light: #fff2e3;
    --c-buttercup: #fff2e3;
  }

  html {
    min-height: 100vh;
    height: 100%;
  }
  body {
    height: 100%;
    transition: background-color 200ms linear;
    @apply bg-defaultBg;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: none;
    &:disabled {
      cursor: default;
    }
  }

  body,
  #root {
    display: flex;
    flex-direction: column;
  }

  body {
    @apply font-regular;
    @apply text-base;
    font-weight: 300;
    color: var(--c-black-70);
  }

  main,
  #root {
    flex: 1 0 auto;
  }

  input {
    display: block;
    width: 100%;
    color: var(--c-black);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  img {
    width: 100%;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  label {
    @apply w-full;
  }

  footer {
    flex-shrink: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-medium;
    font-weight: 300;
  }

  a:focus:not(:focus-visible) {
    outline: none;
  }
}

@tailwind utilities;

@layer utilities {
  @variants responsive {
    .container {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 1000px;
      @apply px-8;
    }

    .link {
      @apply text-forest;
      @apply hover:text-forest--hover;
      @apply font-bold;
    }

    .container--lg {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 1500px;
      @apply px-8;
    }

    .container--sm {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: var(--bp-md);
      @apply px-8;
    }
  }
}

@tailwind components;

@import "./typography.css";
@import "./animations.css";
@import "./components/snackbars.css";
@import "./components/button.css";
@import "./components/modal.css";
@import "./components/textFields.css";
@import "./components/percentageField.css";
@import "./components/incrementalSlider.css";
@import "./components/select.css";
@import "./components/radio.css";
@import "./components/meter.css";
@import "./components/codeField.css";
@import "./components/ol.css";
@import "./components/stripe.css";
@import "./components/retreatButton.css";
@import "./components/videoPlayer.css";
@import "./components/disablityGraph.css";
@import "./components/lifeInsuranceIntro.css";
@import "./components/inviteModal.css";
@import "./components/checkbox.css";
@import "./components/wishesStep.css";
