.radio--disabled label {
  cursor: not-allowed;
}

.radio-check {
  @apply min-w-[32px];
}

.radio input[type="radio"]:checked + .radio-check {
  @apply bg-forest;
  @apply border-forest;
  @apply text-white;
}

.radio input[type="radio"]:not(:checked) + .radio-check,
.radio input[type="radio"]:not(:checked) + .radio-item-content-wrapper .radio-check {
  @apply text-transparent;
}
.radio input[type="radio"]:focus + .radio-check {
  @apply border-forest;
  @apply shadow-inputFocus;
}

.radio input[type="radio"]:checked + .radio-item-content-wrapper .radio-check {
  @apply bg-salamander;
  @apply border-salamander;
  @apply text-white;
}

.radio input[type="radio"]:focus + .radio-item-content-wrapper .radio-check {
  @apply border-salamander;
}

.radio input[type="radio"] + .radio-item-content-wrapper {
  @apply p-4;
  @apply border;
  @apply border-2;
  @apply border-transparent;
  @apply rounded;
  @apply grid;
  @apply gap-2;
  @apply w-full;
  @apply h-full;
  grid-template-columns: auto 32px;
}

.radio input[type="radio"]:checked + .radio-item-content-wrapper {
  @apply border-salamander;
  @apply shadow-md;
}

.radio input[type="radio"] + .radio-item-content-wrapper .radio-check {
  order: 2;
}

/* estate plan specific styles */
.estate-plan-select .radio-items-wrapper {
    @apply md:grid;
    @apply grid-cols-2;
}
