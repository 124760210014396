.incrementalSlider {
  -webkit-appearance: none;
  --sliderThumbSize: 28px;
  width: 100%;
  outline: none;
  opacity: 0.7;
  height: 2px;
  @apply bg-grey-400;
  margin: 15px 0;


  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--sliderThumbSize);
    height: var(--sliderThumbSize);
    border: 1px solid var(--c-grey-400);
    border-radius: 100%;
    background: white url("../../assets/icons/dual-chevron.svg") no-repeat 46%
      46%;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.24),
      0 8px 12px 0 rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: var(--sliderThumbSize);
    height: var(--sliderThumbSize);
    border: 1px solid var(--c-grey-400);
    border-radius: 100%;
    background: white url("../../assets/icons/dual-chevron.svg") no-repeat 46%
      46%;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.24),
      0 8px 12px 0 rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
}
